import React, { useCallback, useMemo } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DualSplit from "../components/sections/dual-split"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const { t } = useTranslation("components")
  const tModal = t("contact_modal")

  return (
    <Layout>
      <Seo title="404: Not found" />
      <section className="section">
        <div className="container is-max-desktop">
          <div className="columns is-centered">
            <div className="column is-6">
              <p className="title is-2 has-text-primary">{tModal.title}</p>
              <p className="subtitle is-4 ">{tModal.subtitle}</p>
              <form
                className="has-text-white mb-5"
                action="https://formsubmit.co/cb2d1ac9691d4f0df721cfb1b28e1347"
                method="POST"
              >
                <div className="field ">
                  <label className="label">{tModal.form.name}</label>
                  <div className="control">
                    <input
                      className="input"
                      name="nom"
                      type="text"
                      placeholder="John Wayne"
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">{tModal.form.email}</label>
                  <div className="control">
                    <input
                      className="input"
                      type="email"
                      name="email"
                      placeholder="somebody@company.com"
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">{tModal.form.phone}</label>
                  <div className="control">
                    <input
                      className="input"
                      name="phone"
                      type="phone"
                      placeholder="+32 456 78 90 12"
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">{tModal.form.your_message}</label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="Hello, ..."
                      name="message"
                    ></textarea>
                  </div>
                </div>
                <input
                  type="hidden"
                  name="_subject"
                  value="SOB : Nouvelle demande"
                />
                <input
                  type="hidden"
                  name="_cc"
                  value="dev@codefathers.be"
                ></input>
                <input
                  type="hidden"
                  name="_autoresponse"
                  value="Thank you for contacting Sem'On Business team, we will get back to you as soon as possible : "
                ></input>
                <div className="control">
                  <input
                    type="submit"
                    className="button is-link is-fullwidth has-text-weight-bold"
                    value={tModal.form.submit}
                  />
                </div>
              </form>
              <p className="mb-3">
                {tModal.call_us_at}{" "}
                <a href="tel::03252462725">
                  <b>+32 (0) 52 46 27 25</b>
                </a>
              </p>
              <p className="mb-3">
                {tModal.email_us_at}{" "}
                <a href="mailto:info@semonbusiness.com">
                  <b>info@semonbusiness.com</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <DualSplit />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
